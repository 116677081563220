/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  images: string[];
}

const CaseCards: FC<IProps> = ({ images }) => (
  <div className={styles.cards}>
    {images.map((image, i) => (
      <div key={i} className={styles.card}>
        <img src={image} alt={`card-${i}`} />
      </div>
    ))}
  </div>
);

export { CaseCards };
