import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  image: string;
  title: string;
  text: string;
  backgroundColor: string;
  textColor?: string;
}

const CaseTitleTerm: FC<IProps> = ({
  image,
  title,
  text,
  backgroundColor,
  textColor,
}) => (
  <div className={styles.term}>
    <img src={image} style={{ backgroundColor }} alt="" />

    <div className={styles.term__content}>
      <h4 style={{ color: textColor }}>{title}</h4>
      <span style={{ color: textColor }}>{text}</span>
    </div>
  </div>
);

export { CaseTitleTerm };
