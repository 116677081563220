/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styles from './styles.module.scss';
import { t } from '../../../i18n';

interface IProps {
  handler: (val: string) => void;
  hasError?: boolean;
  error?: string;
  id?: string;
}

const Captcha: FC<IProps> = ({ handler, hasError, error, id = 'captcha' }) => {
  const [captchaCallbackAdded, setCaptchaCallbackAdded] = useState(false);

  useEffect(() => {
    const captchaTimer = setTimeout(() => {
      // @ts-ignore
      window.onCaptchaLoad = function () {
        // @ts-ignore
        if (!window?.grecaptcha) return;
        // @ts-ignore
        window.grecaptcha.render(id, {
          sitekey: process.env.GATSBY_CAPTCHA_KEY,
          callback: handler,
        });
      };
      setCaptchaCallbackAdded(true);
    }, 1000);

    return () => clearTimeout(captchaTimer);
  }, []);

  return (
    <div className={styles.captcha}>
      <div id={id} className="captcha__container" />
      {(hasError || error) && (
        <div className={styles.captcha__error}>
          {(error && t(error)) || (hasError && t('contacts.fill_this_field'))}
        </div>
      )}

      <Helmet>
        {captchaCallbackAdded && (
          <script
            src="https://www.google.com/recaptcha/api.js?onload=onCaptchaLoad&amp;render=explicit&amp;hl=ru"
            async
            defer
          />
        )}
      </Helmet>
    </div>
  );
};

export { Captcha };
