import React, { FC, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { Platform } from '../common/CaseResult/types';
import { usePathPrefix } from '../../../constants/hooks';
import useScreen from '../../../utils/hooks/useScreen';
import { ICaseFrontmatter } from '../../../queries/cases/types';
import { StackList } from './StackList';
import { PlatformTabs } from './PlatformTabs';
import { CaseOffer } from '../common/CaseOffer';
import { Device } from './Device';
import { CaseHeader } from '../common/CaseHeader';
import AppStore from '../../../images/LinkStore/AppStore.png';
import GooglePlay from '../../../images/LinkStore/GooglePlay.png';

type IProps = {
  page: {
    frontmatter: ICaseFrontmatter;
    fields: { pageLang: string };
    html: string;
  };
};

const ArchiveCase: FC<IProps> = ({ page }) => {
  const { frontmatter, html } = page;

  const prefix = usePathPrefix();

  const [platform, setPlatform] = useState<Platform>(
    +Object.keys(frontmatter.result.screens)[0] as any
  );

  const variants = useMemo<[string, string][]>(
    () => frontmatter.result.screens[platform] || [],
    [prefix, platform, frontmatter.result.screens]
  );

  const { linkStore } = frontmatter.result;
  const availablePlatforms = useMemo(
    () =>
      Object.keys(frontmatter.result.screens).map(
        (p: unknown) => frontmatter.result.platforms[p as Platform]
      ),
    []
  );

  const handleTabChange = (i: number) =>
    setPlatform(+Object.keys(frontmatter.result.screens)[i] as any);

  const { isMobile } = useScreen();
  return (
    <>
      <CaseHeader
        link={frontmatter.link}
        color={frontmatter.color}
        title={frontmatter.title}
        platforms={availablePlatforms}
        description={frontmatter.description}
      />
      <section className={styles.info}>
        {isMobile ? (
          <>
            <StackList
              list={frontmatter.stack.mobileList}
              color={frontmatter.color}
            />
            <PlatformTabs
              tabs={availablePlatforms}
              color={frontmatter.color}
              activeTab={platform}
              onChange={handleTabChange}
            />
            <Device
              variants={variants}
              platform={platform}
              activeDevice={frontmatter.result.platforms[platform][2]}
              rotate={frontmatter.result.rotate}
            />
            <div
              className={styles.info__description}
              dangerouslySetInnerHTML={{ __html: html }}
            />
            <div className={styles.store_link}>
              {linkStore?.iOS ? (
                <a href={linkStore.iOS} target="_blanck">
                  <img src={AppStore} alt="" />
                </a>
              ) : (
                <></>
              )}
              {linkStore?.Android ? (
                <a href={linkStore.Android} target="_blanck">
                  <img src={GooglePlay} alt="" />
                </a>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <>
            <div className={styles.info__container}>
              <StackList
                list={frontmatter.stack.mobileList}
                color={frontmatter.color}
              />
              <PlatformTabs
                tabs={availablePlatforms}
                color={frontmatter.color}
                activeTab={platform}
                onChange={handleTabChange}
              />
              <div
                className={styles.info__description}
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <div className={styles.store_link}>
                {linkStore?.iOS ? (
                  <a href={linkStore.iOS} target="_blanck">
                    <img src={AppStore} alt="" />
                  </a>
                ) : (
                  <></>
                )}
                {linkStore?.Android ? (
                  <a href={linkStore.Android} target="_blanck">
                    <img src={GooglePlay} alt="" />
                  </a>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <Device
              variants={variants}
              platform={platform}
              activeDevice={frontmatter.result.platforms[platform][2]}
              rotate={frontmatter.result.rotate}
            />
          </>
        )}
      </section>
      <CaseOffer
        ideaImg="/case/manly/idea.svg"
        backgroundColor="#262043"
        mainBackroundColor="#262043"
      />
    </>
  );
};

export { ArchiveCase };
