/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import React, { FC } from 'react';
import root from '../../styles.module.scss';
import styles from './styles.module.scss';
import { CaseInfo } from '../CaseInfo';
import { CasePerson } from '../CasePerson';
import { isTooDark } from '../../../../utils/color';
import { t } from '../../../../i18n';

interface IProps {
  descriptionParagraphs: string[];
  mostDifficultParagraphs: string[];
  solutionsParagraphs?: string[] | null;
  conclusionsParagraphs?: string[] | null;
  adviceText?: string;
  backgroundColor: string;
  linesColor: string;
  projectManagerWords?: {
    name: string;
    position: string;
    text: string;
    img: string;
  };
}

const CaseProcess: FC<IProps> = ({
  descriptionParagraphs,
  mostDifficultParagraphs,
  solutionsParagraphs,
  conclusionsParagraphs,
  adviceText,
  projectManagerWords,
  backgroundColor,
  linesColor,
}) => {
  const isColorDark = isTooDark(backgroundColor);
  // TODO: Need added color props if added custom color on template
  const fontColor = isColorDark ? '#ffffff' : '#272727';

  const solutions = solutionsParagraphs?.map(
    (paragraph) => paragraph.replace(/\\n/g, '<br />') || ''
  );

  const conclusions = conclusionsParagraphs?.map(
    (paragraph) => paragraph.replace(/\\n/g, '<br />') || ''
  );

  return (
    <div
      className={classNames(root.screen, root.small)}
      style={{ backgroundColor, color: fontColor }}
    >
      <div className={styles.wrap}>
        <h2 style={{ color: fontColor }}>{t('case.process')}</h2>

        {descriptionParagraphs.map((paragraph, i) => (
          <p key={i} dangerouslySetInnerHTML={{ __html: paragraph }} />
        ))}

        <div className={styles.point}>
          <div className={styles.point__item}>
            <span
              style={{ background: linesColor ?? '' }}
              className={styles.point__line__desktop}
            />
            <div className={styles.point__item__mobile}>
              <span
                style={{ background: linesColor ?? '' }}
                className={styles.point__line__mobile}
              />
              <h5>{t('case.hardest_part')}</h5>
              {mostDifficultParagraphs.map((paragraph, i) => (
                <p key={i} dangerouslySetInnerHTML={{ __html: paragraph }} />
              ))}
            </div>
          </div>

          {conclusions && conclusions.length > 0 && (
            <div className={styles.point__item}>
              <span
                style={{ background: linesColor ?? '' }}
                className={styles.point__line__desktop}
              />
              <div className={styles.point__item__mobile}>
                <span
                  style={{ background: linesColor ?? '' }}
                  className={styles.point__line__mobile}
                />
                <h5>{t('case.conclusions')}</h5>
                {conclusions.map((paragraph, i) => (
                  <p key={i} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
              {projectManagerWords && (
                <CasePerson {...projectManagerWords} fontColor={fontColor} />
              )}
            </div>
          )}

          {solutions && solutions.length > 0 && (
            <div className={styles.point__item}>
              <span
                style={{ background: linesColor ?? '' }}
                className={styles.point__line__desktop}
              />
              <div className={styles.point__item__mobile}>
                <span
                  style={{ background: linesColor ?? '' }}
                  className={styles.point__line__mobile}
                />
                <h5>{t('case.how_we_solved')}</h5>
                {solutions.map((paragraph, i) => (
                  <p key={i} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
              {projectManagerWords && (
                <CasePerson {...projectManagerWords} fontColor={fontColor} />
              )}
            </div>
          )}
        </div>

        {adviceText && <CaseInfo text={adviceText} />}
      </div>
    </div>
  );
};

export { CaseProcess };
