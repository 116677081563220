import React, { CSSProperties, FC, useCallback } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { CaseButton } from '../CaseButton';
import { isTooDark } from '../../../../utils/color';

interface IProps {
  current: number;
  value: number;
  onChange: (val: any) => void;
  activeClass?: string;
  color: Record<'primary' | 'secondary', string>;
  description?: string;
}

const CaseResultButton: FC<IProps> = ({
  children,
  current,
  value,
  onChange,
  color: { primary, secondary },
  description,
}) => {
  const handle = useCallback(() => onChange(value), [onChange, value]);
  const isActive = current === value;

  const isColorDark = isTooDark(primary);
  // TODO: Need added color props if added custom color on template
  const fontColor = isColorDark ? '#ffffff' : '#272727';
  const fontColorButtonInactive = isColorDark ? primary : '#272727';

  const style: CSSProperties = {
    backgroundColor: isActive ? primary : secondary,
    color: isActive ? fontColor : fontColorButtonInactive,
  };

  return (
    <>
      <CaseButton
        className={classNames(styles.switch, { [styles.active]: isActive })}
        onClick={handle}
        style={style}
      >
        {children}
        <span />
      </CaseButton>
      {isActive && description && (
        <p
          className={styles.description}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </>
  );
};

export { CaseResultButton };
