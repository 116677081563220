/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  images?: string[];
  active?: number;
}

const CaseDesktop: FC<IProps> = ({ images, active = 0 }, index) => (
  <div className={styles.wrap}>
    <div className={styles.bezel}>
      {images &&
        images.map(
          (image, i) =>
            i === active && (
              <div key={`${index}-image-case`} className={styles.image}>
                <img src={image} alt="" />
              </div>
            )
        )}
    </div>
  </div>
);

export { CaseDesktop };
