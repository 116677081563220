/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../constants/hooks';

export type DeviceSettings = {
  width: number;
  height: number;
  screenWidth?: number;
  screenHeight?: number;
  x?: number;
  y?: number;
  zIndex?: number;
};

interface IProps {
  images?: string[];
  active?: number;
  activeDevice?: string;
  deviceSettings?: DeviceSettings;
}

const CaseDevice: FC<IProps> = ({
  images,
  activeDevice,
  active = 0,
  deviceSettings = {
    width: 320,
    height: 650,
    screenWidth: 278,
    screenHeight: 493,
    x: 20,
    y: 75,
    zIndex: 1,
  },
}) => {
  const prefix = usePathPrefix();

  return (
    <div className={styles.wrap}>
      <div className={styles.bezel}>
        <svg
          viewBox={`0 0 ${deviceSettings.width} ${deviceSettings.height}`}
          style={{
            maxWidth: deviceSettings.width,
            maxHeight: deviceSettings.height,
          }}
          width="100%"
          height="100%"
          preserveAspectRatio="none"
        >
          {deviceSettings?.zIndex && (
            <image href={`${prefix}${activeDevice}`} />
          )}
          {images &&
            images.map((image, i) => (
              <svg
                width={deviceSettings.screenWidth}
                height={deviceSettings.screenHeight}
                x={deviceSettings.x}
                y={deviceSettings.y}
                viewBox={`0 0 ${deviceSettings.screenWidth} ${deviceSettings.screenHeight}`}
                opacity={i === active ? 1 : 0}
                className={styles.image}
                key={`${i}-${image}`}
                preserveAspectRatio="none"
              >
                <image href={image} />
              </svg>
            ))}
          {!deviceSettings?.zIndex && (
            <image href={`${prefix}${activeDevice}`} />
          )}
        </svg>
      </div>
    </div>
  );
};

/*

 */
export { CaseDevice };
