/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { CaseDevice, DeviceSettings } from '../../common/CaseDevice';
import { Platform } from '../../common/CaseResult/types';

type IProps = {
  variants: [string, string][];
  activeDevice: string;
  platform: Platform;
  rotate: boolean;
};

const deviceSettings: Record<string, DeviceSettings> = {
  android: {
    width: 320,
    height: 650,
    screenWidth: 278,
    screenHeight: 493,
    x: 20,
    y: 75,
    zIndex: 1,
  },
  ios: {
    width: 320,
    height: 650,
    screenWidth: 278,
    screenHeight: 493,
    x: 20,
    y: 75,
    zIndex: 1,
  },
  desktop: {
    width: 607,
    height: 351,
    screenWidth: 470,
    screenHeight: 292,
    x: 69,
    y: 20,
    zIndex: 1,
  },
  'android-rotated': {
    width: 650,
    height: 320,
    screenWidth: 493,
    screenHeight: 278,
    x: 80,
    y: 23,
    zIndex: 1,
  },
  'ios-rotated': {
    width: 650,
    height: 320,
    screenWidth: 493,
    screenHeight: 278,
    x: 80,
    y: 23,
    zIndex: 1,
  },
};

const Device: FC<IProps> = ({ variants, activeDevice, platform, rotate }) => {
  const [variant, setVariant] = useState(0);

  const images = useMemo(() => variants.map((v) => v[1]), [variants]);

  const switchToNextVariant = useCallback(() => {
    if (variant === variants.length - 1) return;
    setVariant(variant + 1);
  }, [variant, setVariant, variants]);

  const switchToPreviousVariant = useCallback(() => {
    if (variant === 0) return;
    setVariant(variant - 1);
  }, [variant, setVariant, variants]);

  useEffect(() => setVariant(0), [platform]);

  const activeDeviceName = activeDevice.split('/').pop()!.split('.').shift();

  return (
    <div
      className={classNames(styles.device, {
        [styles.vertical]: rotate,
      })}
    >
      <div className={styles.device__container}>
        <CaseDevice
          images={images}
          activeDevice={activeDevice}
          active={variant}
          deviceSettings={deviceSettings[activeDeviceName!]}
        />
      </div>
      <div className={styles.device__pagination}>
        <div
          onClick={switchToPreviousVariant}
          className={classNames(styles.arrow, styles.arrow_left, {
            [styles.arrow_transparency]: variant === 0,
          })}
        />
        <div className={styles.thumbs}>
          {variants.map((_, i) => (
            <span
              key={i}
              className={classNames(styles.thumbs__item, {
                [styles.thumbs__item_active]: i === variant,
              })}
              onClick={() => setVariant(i)}
            />
          ))}
        </div>
        <div
          className={classNames(styles.arrow, {
            [styles.arrow_transparency]: variant === variants.length - 1,
          })}
          onClick={switchToNextVariant}
        />
      </div>
    </div>
  );
};

export { Device };
