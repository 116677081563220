import React, { FC } from 'react';
import { CaseCell } from '../SmallCaseCell';
import { ICaseQueryEdge } from '../../../../queries/cases/types';
import styles from './styles.module.scss';
import { Slider } from '../../../common/Slider';
import { t } from '../../../../i18n';

interface IProps {
  cases: ICaseQueryEdge[];
  buttonsColor: string;
}

const CaseSimilar: FC<IProps> = ({ cases, buttonsColor }) => (
  <div className={styles.wrap}>
    <h2>{t('case.similar_projects')}</h2>
    <Slider
      className={styles.slider}
      sliderProps={{
        items: 2,
        mouseDrag: true,
        slideBy: 'page',
        gutter: 20,
        controls: cases.length > 1,
        nav: false,
        center: false,
        lazyload: false,
        swipeAngle: 45,
        loop: false,
        responsive: {
          1: {
            items: 1,
            controls: cases.length > 1,
          },
          590: {
            items: 2,
            controls: cases.length > 2,
          },
          1600: {
            items: 3,
            controls: cases.length >= 3,
          },
        },
      }}
      color={buttonsColor}
    >
      {cases.map((item) => (
        <div className={styles.item} key={item.node.id}>
          <CaseCell
            item={item.node}
            key={item.node.id}
            appearance="round"
            from="/cases"
            className={styles.cell}
            deviceClassName={styles.device}
          />
        </div>
      ))}
    </Slider>
  </div>
);

export { CaseSimilar };
