import React, { FC } from 'react';
import { CaseButton } from '../CaseButton';
import { usePathPrefix } from '../../../../constants/hooks';
import styles from './styles.module.scss';
import { t } from '../../../../i18n';
import { isTooDark } from '../../../../utils/color';
import { TLink } from '../../../../i18n/TLink';

interface IProps {
  background?: string;
  backgroundColor?: string;
  buttonColor?: string;
  ideaImg: string;
  mainBackroundColor: string;
}

const CaseOffer: FC<IProps> = ({
  background,
  backgroundColor,
  ideaImg,
  buttonColor,
  mainBackroundColor,
}) => {
  const prefix = usePathPrefix();
  const backgroundImage = `url('${prefix}${background}')`;

  const isButtonDark = isTooDark(buttonColor || '');

  const isColorDark = isTooDark(mainBackroundColor);
  // TODO: Need added color props if added custom color on template
  const fontColor = isColorDark ? '#ffffff' : '#272727';

  return (
    <div className={styles.offer} style={{ backgroundImage, backgroundColor }}>
      <div className={styles.idea}>
        <img src={`${prefix}${ideaImg}`} alt="" />
      </div>

      <div className={styles.content}>
        <h2 style={{ color: fontColor }}>{t('case.discuss')}</h2>
        <p style={{ color: fontColor }}>{t('case.we_will_tell')}</p>

        <TLink to="/order">
          <CaseButton
            style={{
              backgroundColor: buttonColor,
              color: isButtonDark ? '#fff' : '#222',
            }}
          >
            <>{t('case.leave_a_request')}</>
          </CaseButton>
        </TLink>
      </div>
    </div>
  );
};

export { CaseOffer };
