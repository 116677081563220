import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../constants/hooks';

interface IProps {
  imagePath?: string;
  className?: string;
  color?: string;
}

const CaseStackCard: FC<IProps> = ({
  imagePath,
  color,
  children,
  className,
}) => {
  const prefix = usePathPrefix();
  return (
    <div className={classNames(styles.card, className)}>
      <span className={styles.card__line} style={{ backgroundColor: color }} />
      {imagePath && <img src={`${prefix}${imagePath}`} alt="" />}
      <span>{children}</span>
    </div>
  );
};

export { CaseStackCard };
