import React, { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../constants/hooks';

interface IProps {
  text: ReactNode;
}

const CaseInfo: FC<IProps> = ({ text }) => {
  const prefix = usePathPrefix();
  return (
    <div className={styles.info}>
      <img src={`${prefix}/case/common/info.svg`} alt="i" />
      {text}
    </div>
  );
};

export { CaseInfo };
