/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps extends HTMLAttributes<HTMLButtonElement> {}

const CaseButton: FC<IProps> = (props) => (
  <button {...props} className={classNames(styles.button, props.className)} />
);

export { CaseButton };
