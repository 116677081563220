import React, { FC, useState, useCallback } from 'react';
import { CaseButton } from '../CaseButton';
import { t } from '../../../../i18n';
import { PresentationSuccesModal } from './PresentationSuccessModal';
import { PresentationModal } from './PresentationModal';

interface IProps {
  background?: string;
  backgroundColor?: string;
  buttonColor?: string;
}

const CasePresentation: FC<IProps> = ({
  background,
  backgroundColor,
  buttonColor,
}) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [isSuccessShown, setIsSuccessShown] = useState(false);

  const toggleModal = useCallback(
    () => setIsModalShown(!isModalShown),
    [isModalShown, setIsModalShown]
  );

  const toggleSuccess = useCallback(
    () => setIsSuccessShown(!isSuccessShown),
    [isSuccessShown, setIsSuccessShown]
  );

  return (
    <>
      <CaseButton
        style={{
          backgroundColor: buttonColor,
          color: '#272727',
          fontSize: '17px',
          fontWeight: 700,
          width: '100%',
        }}
        onClick={toggleModal}
      >
        {t('cases.presentation.get')}
      </CaseButton>
      {isModalShown && (
        <PresentationModal onClose={toggleModal} onSuccess={toggleSuccess} />
      )}
      {isSuccessShown && <PresentationSuccesModal onClose={toggleSuccess} />}
    </>
  );
};

export { CasePresentation };
