import React, { FC } from 'react';
import { ICaseFrontmatter } from '../../../queries/cases/types';
import styles from './styles.module.scss';
import { CaseHeader } from '../common/CaseHeader';
import { usePathPrefix } from '../../../constants/hooks';
import useScreen from '../../../utils/hooks/useScreen';
import { t } from '../../../i18n';

interface IProps {
  page: {
    frontmatter: ICaseFrontmatter;
    fields: { pageLang: string };
    html: string;
  };
}

const TempoCasePageContent: FC<IProps> = ({ page }) => {
  const { title, description } = page.frontmatter;

  const prefix = usePathPrefix();
  const { isMobile } = useScreen();

  return (
    <div className={styles.caseInDevelop}>
      {!isMobile && (
        <CaseHeader color="#C9C3DB" title={title} description={description} />
      )}
      <div className={styles.caseInDevelop__content}>
        <svg width={96} height={96}>
          <use
            xlinkHref={`/${prefix}case/common/archive.svg#icon`}
            width={96}
            height={96}
          />
        </svg>
        <h4>{t('in_develop.process')}</h4>
        <p>{t('in_develop.come_back')}</p>
      </div>
    </div>
  );
};

export { TempoCasePageContent };
