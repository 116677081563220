import React, { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import { Slider } from '../../../common/Slider';
import useScreen from '../../../../utils/hooks/useScreen';

export type stack = {
  iconName?: string;
  text: string;
};

interface IProps {
  arr: stack[];
  renderCard: (el: stack) => ReactNode;
}

const ListSlider: FC<IProps> = ({ arr, renderCard }) => {
  const { isMobile } = useScreen();

  return isMobile ? (
    <Slider
      sliderProps={{
        mouseDrag: true,
        slideBy: 'page',
        controls: false,
        nav: false,
        center: true,
        loop: false,
        lazyload: false,
        swipeAngle: 45,
        gutter: 8,
      }}
      className={styles.slider}
      containerClassName={styles.container}
    >
      {arr.map((el) => (
        <div key={el.text}>{renderCard(el)}</div>
      ))}
    </Slider>
  ) : (
    <div className={styles.cards}>{arr.map((el) => renderCard(el))}</div>
  );
};

export { ListSlider };
