import React, { FC } from 'react';
import { CaseTitleWithDevice } from '../common/CaseTitleWithDevice';
import { CaseTitleWithDeviceLeft } from '../common/CaseTitleWithDevice/CaseTitleWithDeviceLeft';
import { CaseTaskSolution } from '../common/CaseTaskSolution';
import { CaseProcess } from '../common/CaseProcess';
import { CaseCards } from '../common/CaseCards';
import { CaseStack } from '../common/CaseStack';
import { CaseStackCard } from '../common/CaseStackCard';
import { CaseResult, buttonProps } from '../common/CaseResult';
import { CaseResultButton } from '../common/CaseResultButton';
import { CaseOffer } from '../common/CaseOffer';
import { CaseSimilar } from '../common/CaseSimilar';
import { ICaseQueryEdge, ICaseFrontmatter } from '../../../queries/cases/types';
import { CaseDevice } from '../common/CaseDevice';
import { CasePerson } from '../common/CasePerson';
import { CaseFeedback } from '../common/CaseFeedback';
import { changePath } from '../../../utils/hooks';

interface IProps {
  page: {
    frontmatter: ICaseFrontmatter;
    html: string;
  };
  projects: ICaseQueryEdge[];
  slug: string;
}

const renderButton = (
  el: buttonProps,
  color: ICaseFrontmatter['result']['colors']
) => (
  <CaseResultButton
    color={color}
    value={el.value}
    onChange={el.onChange}
    current={el.current}
    description={el.variant[2]}
    key={el.variant[0]}
  >
    {el.variant[0]}
  </CaseResultButton>
);

const CasePageContent: FC<IProps> = ({ slug, page, projects }) => {
  const post = page.frontmatter;
  const similarProjects = projects.filter(
    (project) =>
      post.similarCases &&
      !!post.similarCases.find(
        (similar) => changePath(project.node.fileAbsolutePath) === similar
      )
  );

  return (
    <>
      {post.preview && (
        <CaseTitleWithDevice
          left={
            <CaseTitleWithDeviceLeft
              {...post.preview}
              title={post.title}
              mainBackroundColor={post.result.colors.primary}
            />
          }
          right={
            post.preview.deviceImage && (
              <CaseDevice
                deviceSettings={{
                  width: 375,
                  height: 740,
                }}
                activeDevice={post.preview.deviceImage}
              />
            )
          }
          background={post.preview.backgroundImage}
          webMobile={post.preview.webMobile}
        />
      )}
      {post.taskAndSolution && (
        <CaseTaskSolution
          {...post.taskAndSolution}
          webMobile={post.preview.webMobile}
        />
      )}
      {post.process && <CaseProcess {...post.process} />}
      {post.cards && <CaseCards images={post.cards} />}
      {post.projectManagerWords && <CasePerson {...post.projectManagerWords} />}
      {post.stack && (
        <CaseStack
          {...post.stack}
          renderCard={(el) => (
            <CaseStackCard
              color={post.stack.color}
              key={el.text}
              imagePath={el.iconName}
            >
              {el.text}
            </CaseStackCard>
          )}
        />
      )}
      {post.feedback && (
        <CaseFeedback
          feedback={post.feedback}
          buttonsColor={post.result.colors.primary}
        />
      )}
      {post.result && (
        <CaseResult
          {...post.result}
          renderButton={(props) => renderButton(props, post.result.colors)}
          presentation={post.presentation}
        />
      )}
      {post.offer && (
        <CaseOffer
          {...post.offer}
          backgroundColor={post.offer.backgroundColor}
          mainBackroundColor={post.result.colors.primary}
        />
      )}
      {similarProjects.length > 0 && (
        <CaseSimilar
          cases={similarProjects}
          buttonsColor={post.result?.colors.primary}
        />
      )}
    </>
  );
};

export { CasePageContent };
