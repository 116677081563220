/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { ICaseFrontmatter } from '../../../../queries/cases/types';
import styles from './styles.module.scss';

type IProps = {
  list: ICaseFrontmatter['stack']['mobileList'];
  color: string;
};

const StackList: FC<IProps> = ({ list, color }) => (
  <div className={styles.stack}>
    {list.map((el, i) => (
      <div
        key={i}
        className={styles.stack__item}
        style={{ backgroundColor: color }}
      >
        {el.text}
      </div>
    ))}
  </div>
);

export { StackList };
