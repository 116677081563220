import classNames from 'classnames';
import React, { FC } from 'react';
import root from '../../styles.module.scss';
import styles from './styles.module.scss';

interface IProps {
  text: string;
  img: string;
  name: string;
  position: string;
  fontColor?: string;
}

const CasePerson: FC<IProps> = ({ text, img, name, position, fontColor }) => (
  <div className={classNames(root.screen, root.small, styles.person)}>
    {img && (
      <div className={styles.person__image}>
        <img src={img} alt="" />
      </div>
    )}

    <div className={styles.person__text}>
      <h4 style={{ color: fontColor }}>{name}</h4>

      <div className={styles.person__position} style={{ color: fontColor }}>
        {position}
      </div>

      <div className={styles.person__quote} style={{ color: fontColor }}>
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  </div>
);

export { CasePerson };
