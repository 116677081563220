/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import styles from './styles.module.scss';

type IProps = {
  color: string;
  tabs: string[][];
  onChange: (i: number) => void;
  activeTab: number;
};

const PlatformTabs: FC<IProps> = ({ color, tabs, onChange, activeTab }) => (
  <div
    className={styles.tabs}
    style={{
      border: `1px solid ${color}`,
    }}
  >
    {tabs.map((el, i) => (
      <div
        key={i}
        className={styles.tabs__item}
        style={{
          backgroundColor: i === activeTab ? color : 'transparent',
          color: i === activeTab ? '#ffffff' : color,
        }}
        onClick={() => onChange(i)}
      >
        {el[0]}
      </div>
    ))}
  </div>
);

export { PlatformTabs };
