/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import { Breadcrumbs } from '../../../common/Breadcrumbs';
import { IconComponent } from '../../../common/Icon';
import { AndroidIcon } from '../../../icons/AndroidIcon';
import { AppleIcon } from '../../../icons/AppleIcon';
import { WebIcon } from '../../../icons/WebIcon';

const platformIcons: Record<string, FC<any>> = {
  Android: AndroidIcon,
  iOS: AppleIcon,
  Desktop: WebIcon,
};

type IProps = {
  color: string;
  title: string;
  description?: string;
  platforms?: string[][];
  link?: string;
};

const CaseHeader: FC<IProps> = ({
  color,
  title,
  description,
  platforms,
  link,
}) => (
  <header className={styles.header} style={{ backgroundColor: color }}>
    <div>
      <div className={classNames(root.breadcrumbs, styles.breadcrumbs)}>
        <Breadcrumbs title={title} />
      </div>
      <h2 className={styles.header__title}>{title}</h2>
      {description && (
        <p className={styles.header__description}>{description}</p>
      )}
    </div>
    <div className={styles.header__additionalInfo}>
      {platforms &&
        platforms.map((el, i) => (
          <span key={i} className={styles.header__icon}>
            <IconComponent
              name={el[0]}
              icons={platformIcons}
              iconProps={{ color: '#FFFFFF40', width: 24, height: 24 }}
            />
          </span>
        ))}
      {link && (
        <a href={link} target="_blank" rel="noreferrer">
          {link}
        </a>
      )}
    </div>
  </header>
);

export { CaseHeader };
