/* eslint-disable no-unneeded-ternary */
import React, { FC, useCallback } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
  checked: boolean;
  handler: (val: boolean) => void;
}

const Checkbox: FC<IProps> = ({ checked, handler }) => {
  const onChange = useCallback(
    (event) => {
      handler(event.target.checked);
    },
    [handler, checked]
  );
  return (
    <div className={classnames(styles.checkbox, { [styles.checked]: checked })}>
      <input
        type="checkbox"
        checked={checked ? true : false}
        onChange={onChange}
      />

      {checked && (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 9L7 14L16 5L14.59 3.58L7 11.17L3.41 7.59L2 9Z"
          />
        </svg>
      )}
    </div>
  );
};

export { Checkbox };
