/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import root from '../../styles.module.scss';
import styles from './styles.module.scss';
import { t } from '../../../../i18n';

interface IProps {
  taskImg: string;
  taskParagraphs: string[];
  solutionImg: string;
  solutionParagraphs: string[];
  webMobile?: string;
}

const CaseTaskSolution: FC<IProps> = ({
  taskImg,
  taskParagraphs,
  solutionImg,
  solutionParagraphs,
  webMobile,
}) => {
  const solutions = solutionParagraphs.map((paragraph) =>
    paragraph.replace(/\\n/g, '<br />')
  );
  return (
    <div
      className={`${root.small} ${
        webMobile ? styles.wrapWebMobile : styles.wrap
      }`}
    >
      <div className={styles.task}>
        <div className={styles.task__image}>
          <img src={taskImg} alt="Задача" />
        </div>

        <div className={styles.task__text}>
          <h2>{t('case.task')}</h2>
          {taskParagraphs.map((paragraph, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}
        </div>
      </div>

      <div className={styles.task}>
        <div className={styles.task__image}>
          <img src={solutionImg} alt="Решение" />
        </div>

        <div className={styles.task__text}>
          <h2>{t('case.solution')}</h2>

          {solutions.map((paragraph, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}
        </div>
      </div>
    </div>
  );
};

export { CaseTaskSolution };
