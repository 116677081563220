import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
  left: ReactNode;
  right: ReactNode;
  background: string;
  webMobile?: string;
}

const CaseTitleWithDevice: FC<IProps> = ({
  left,
  right,
  background,
  webMobile,
}) => {
  const backgroundImage = `url('${background}')`;

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.withoutImg]: !right,
      })}
      style={{ backgroundImage }}
    >
      <div className={styles.left}>
        <div className={styles.content}>{left}</div>
      </div>
      {right && <div className={styles.device}>{right}</div>}
      {webMobile && (
        <div className={styles.webMobile}>
          <img src={webMobile} alt="" />
        </div>
      )}
    </div>
  );
};

export { CaseTitleWithDevice };
