type formInput = Record<string, string>;

const formInputs: formInput[] = [
  {
    nameInput: 'email',
    label: 'cases.kmm.form.email',
    icon: '/case/restocare/contact_email_kmm.svg',
  },
];

export default formInputs;
