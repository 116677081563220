import React, { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import { CaseInfo } from '../CaseInfo';
import { t } from '../../../../i18n';
import { ListSlider } from '../CaseSlider';

export type stack = {
  iconName?: string;
  text: string;
};

interface IProps {
  mobileList: stack[];
  backendList?: stack[];
  renderCard: (el: stack) => ReactNode;
  infoText: ReactNode;
}

const CaseStack: FC<IProps> = ({
  mobileList,
  backendList,
  renderCard,
  infoText,
}) => (
  <div className={styles.stack}>
    <h2>{t('case.technology')}</h2>
    {backendList && <p>Backend</p>}
    {backendList && <ListSlider arr={backendList} renderCard={renderCard} />}
    {backendList && <p>{t('case.mobile')}</p>}
    <div />
    <ListSlider arr={mobileList} renderCard={renderCard} />
    {infoText && <CaseInfo text={infoText} />}
  </div>
);

export { CaseStack };
